<template>
    <v-container fluid>
        <div>
            <v-btn color="primary" elevation="5" dark x-large
                :style="{ left: '50%', top: '40%', transform: 'translateX(-50%)', width: '160px', height: '70px' }"
                :loading="loading" @click="process">Process</v-btn>
            </div>
            <v-simple-table dense class="record_table">
                <tr class="record_heading_tr">
                    <th>Sl</th>
                    <th>Employee ID</th>
                    <th>Employee</th>
                    <th>Total Leave</th>
                </tr>
                <tr v-for="(item, sl) in earnleaves" :key="sl">
                    <td>{{ sl + 1 }}</td>
                    <td>{{ item.employee.emp_id }}</td>
                    <td>{{ item.employee.name }}</td>
                    <td>{{ item.total_leave }}</td>
                </tr>
            </v-simple-table>
    </v-container>
</template>
<script>
import axios from 'axios';

export default {
    data: () => ({
        earnleaves: [],
        loading: false,
    }),
    created() {
        this.getEarnLeave();
    },
    methods: {
        getEarnLeave() {
            axios.post("/get-earn-leave")
                .then(res => {
                    this.earnleaves = res.data;
                })
        },
        process() {
            axios.post("/add-earn-leave")
                .then(res => {
                    alert(res.data.message);
                    this.earnleaves = res.data.earnleaves;
                })
        }
    }
}
</script>

<style scoped>
.record_table {
    margin-bottom: 5px;
    width: 30%;
}

.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;

}

.record_table th,
.record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center;
    height: 0 !important;
}

.record_table td {
    color: green;
}

.record_heading_tr {
    background: #4caf7773;
}
</style>